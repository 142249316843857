
import { computed } from "vue";
import DeleteReportModal from "@/components/diagnoses/modal/DeleteReportModal.vue";
import {
  diagnoses,
  reportData,
  userphone,
  btnDisabled,
  dbNum,
  role
} from "@/composition/store";
import {
  useEvaluationMode,
  useRequestDiagnoses,
  useShowECGChart
} from "@/composition/index";
import { useI18n } from "vue-i18n";
export default {
  components: {
    DeleteReportModal
  },
  setup() {
    const { t, locale } = useI18n();
    const { switchSynthetic } = useRequestDiagnoses();
    const { updateReport, deleteReport } = useEvaluationMode();
    computed(() => {
      console.log(reportData.value);
    });
    return {
      diagnoses,
      updateReport,
      reportData,
      deleteReport,
      userphone,
      btnDisabled,
      switchSynthetic,
      dbNum,
      t,
      locale,
      role
    };
  }
};
