
import { computed, ref } from "vue";
import {
  tagDataShow,
  isChecked,
  isActive,
  userphone,
  role,
  dbNum,
  modalData,
  dataSwitch,
  diagnosesid
} from "@/composition/store";
import { useI18n } from "vue-i18n";
import { useInitSciChart } from "@/composition/index";
export default {
  setup() {
    const { initSciChart } = useInitSciChart();
    const { t, locale } = useI18n();
    role.value = JSON.parse(localStorage.getItem("userinfo")).role;
    const isShowData = (val) => {
      dataSwitch.value.tag = !val;
      initSciChart();
      // console.log(dataSwitch.value.tag);
    };
    //   return { display: username.value === "77311699" ? "none" : "block" };
    // });
    // const isChecked = ref(false);
    // const focus = ref(null);
    // const switchToggle = (e) => {
    //   // e.preventDefault();
    //   focus.value.focus();
    //   isChecked.value = !isChecked.value;
    //   console.log(e.keyCode);
    //   console.log(isChecked.value);
    //   return false;
    // };
    // const tagModeEnable: HTMLInputElement = <HTMLInputElement>(
    //   document.getElementById("tag-mode")
    // );
    // document.body.addEventListener("keydown", (e) => {
    //   console.log(e.keyCode);
    //   e.preventDefault();
    //   switch (e.keyCode) {
    //     case 32:
    //       tagModeEnable.focus();
    //       tagModeEnable.checked = !tagModeEnable.checked;
    //       console.log(tagModeEnable.checked);
    //   }
    // if (tagModeEnable.checked === true) {
    //   simpleDataPointSelectionModifier.isEnabled = true;
    //   zoomPanModifier.isEnabled = false;
    //   xAxisDragModifier.isEnabled = true;
    //   scichartRoot.setAttribute("data-bs-toggle", "modal");
    //   scichartRoot.setAttribute("data-bs-target", "#exampleModal");
    // } else {
    //   simpleDataPointSelectionModifier.isEnabled = false;
    //   zoomPanModifier.isEnabled = true;
    //   xAxisDragModifier.isEnabled = false;
    //   scichartRoot.removeAttribute("data-bs-toggle");
    //   scichartRoot.removeAttribute("data-bs-target");
    // }
    // });
    return {
      tagDataShow,
      isChecked,
      isActive,
      userphone,
      dbNum,
      modalData,
      isShowData,
      t,
      locale,
      dataSwitch,
      role
      // isShow
      // switchToggle,
      // focus
    };
  }
};
