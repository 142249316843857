
import { onMounted, ref, computed } from "vue";
import {
  diagnoses,
  evaluationTagDataShow,
  selectedModelName,
  userphone,
  dbNum,
  modalData,
  modelName,
  dataSwitch,
  isEvaluated,
  role,
  anomalyData,
} from "@/composition/store";
import { useEvaluationMode } from "@/composition/index";
import { useI18n } from "vue-i18n";
import { useInitSciChart } from "@/composition/index";
export default {
  setup() {
    const { initSciChart } = useInitSciChart();
    const { t, locale } = useI18n();
    const {
      activeEvaluationMode,
      getAnomalyModels,
      updateReport,
    } = useEvaluationMode();
    onMounted(async () => {
      await getAnomalyModels();
    });
    const isShowData = (val) => {
      dataSwitch.value.anomaly = !val;
      initSciChart();
    };

    return {
      diagnoses,
      evaluationTagDataShow,
      selectedModelName,
      userphone,
      dbNum,
      modalData,
      modelName,
      activeEvaluationMode,
      updateReport,
      t,
      locale,
      dataSwitch,
      isShowData,
      isEvaluated,
      role,
      anomalyData,
    };
  },
};
