<template>
  <Loading :active="isLoading" />
  <!-- <EvaluationMode /> -->
  <ChartInfo />
  <div class="container">
    <div class="row">
      <TagInfo />
      <EvaluationInfo />
    </div>
  </div>
  <SciChart />
  <TagNoteModal />
  <ChangeTagNoteModal />
  <DeleteTagNoteModal />
  <DeleteEvaluationTagModal />
  <ModifyEvaluationTagModal />
  <!-- <DeleteReportModal /> -->
</template>

<script lang="ts">
import ChartInfo from "@/components/diagnoses/ChartInfoComponent.vue";
import SciChart from "@/components/diagnoses/SciChartComponent.vue";
import TagNoteModal from "@/components/diagnoses/modal/TagNoteModal.vue";
import ChangeTagNoteModal from "@/components/diagnoses/modal/ChangeTagNoteModal.vue";
import DeleteTagNoteModal from "@/components/diagnoses/modal/DeleteTagNoteModal.vue";
import TagInfo from "@/components/diagnoses/TagInfoComponent.vue";
import EvaluationInfo from "@/components/diagnoses/EvaluationInfoComponent.vue";
import DeleteEvaluationTagModal from "@/components/diagnoses/modal/DeleteEvaluationTagModal.vue";
import ModifyEvaluationTagModal from "@/components/diagnoses/modal/ModifyEvaluationTagModal.vue";
// import DeleteReportModal from "@/components/diagnoses/modal/DeleteReportModal_copy.vue";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import {
  diagnoses,
  selectedPoints,
  tagMode,
  tagListData,
  tagList,
  dbNum,
  diagnosesid,
  isLoading
} from "@/composition/store";
import { useInitSciChart, useEvaluationMode, useShowECGChart } from "@/composition/index";

export default {
  components: {
    ChartInfo,
    SciChart,
    TagNoteModal,
    ChangeTagNoteModal,
    DeleteTagNoteModal,
    TagInfo,
    EvaluationInfo,
    DeleteEvaluationTagModal,
    ModifyEvaluationTagModal
    // DeleteReportModal
  },
  setup() {
    const { initSciChart } = useInitSciChart();
    const { updateReport, getOnlyEvaluation } = useEvaluationMode();
    const { showECGChart, showTagRange } = useShowECGChart();
    const dataReady = ref(false);
    const route = useRoute();
    dbNum.value = route.params.dbNum;
    diagnosesid.value = route.params.diagnosesid;

    onMounted(async () => {
      await showECGChart(diagnosesid.value);
      await showTagRange(diagnosesid.value);
      await getOnlyEvaluation(diagnosesid.value);
      await initSciChart();
      if (dbNum.value === "v2") {
        updateReport(diagnosesid.value);
      }
    });
    return {
      initSciChart,
      diagnoses,
      selectedPoints,
      tagMode,
      tagListData,
      tagList,
      dataReady,
      isLoading
    };
  }
};
</script>

<style scoped></style>
