<template>
  <div class="container-lg">
    <div id="scichart-root"></div>
  </div>
</template>

<style scoped>
#scichart-root {
  width: 100%;
  touch-action: none;
  height: 32.45%;
}
</style>
