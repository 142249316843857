<template>
  <div class="col-12 col-md-6" v-if="role === 'admin' || dbNum === 'v1'">
    <div class="card">
      <h5 class="card-header">
        <button
          class="btn btn-secondary"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#multi-collapse-evaluation"
          aria-expanded="true"
          aria-controls="collapseExample"
          @click="isShowData(dataSwitch.anomaly)"
        >
          {{ t("diagnoses.evaluationinfo.title") }}
        </button>
      </h5>
      <div class="collapse multi-collapse" id="multi-collapse-evaluation">
        <div class="d-flex justify-content-around mt-2">
          <select
            v-model="selectedModelName"
            name=""
            id=""
            placeholder="請選擇 Model"
            class="form-select-inline form-select-sm"
            aria-label="Default select example"
          >
            <option value="" selected disabled>
              {{ t("diagnoses.evaluationinfo.model") }}
            </option>
            <option v-for="item in modelName" :key="item" :value="item">
              {{ item }}
            </option>
          </select>
          <div
            class="btn btn-primary"
            @click="
              activeEvaluationMode(
                selectedModelName,
                diagnoses.data[0].diagnosis_id
              )
            "
          >
            {{ t("diagnoses.evaluationinfo.evaluation") }}
          </div>
          <div class="form-check form-switch d-none d-xl-inline-block mt-2">
            <input
              class="form-check-input"
              type="checkbox"
              id="evaluation-mode"
              v-model="isEvaluated"
            />
            <label class="form-check-label" for="evaluation-mode">{{
              t("diagnoses.taginfo.tagmode")
            }}</label>
          </div>
        </div>
        <div class="card-body">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  {{ t("diagnoses.evaluationinfo.table.channel") }}
                </th>
                <th scope="col">
                  {{ t("diagnoses.evaluationinfo.table.time") }}
                </th>
                <th scope="col">
                  {{ t("diagnoses.evaluationinfo.table.evaluator") }}
                </th>
                <th scope="col">
                  {{ t("diagnoses.evaluationinfo.table.note") }}
                </th>
                <th scope="col" class="d-none d-xl-table-cell">
                  {{ t("diagnoses.evaluationinfo.table.operation") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in evaluationTagDataShow" :key="i">
                <th v-if="item.channel === '5'">avF</th>
                <th v-else-if="item.channel === '4'">aVL</th>
                <th v-else-if="item.channel === '3'">aVR</th>
                <th v-else-if="item.channel === '2'">LEAD 3</th>
                <th v-else-if="item.channel === '1'">LEAD 2</th>
                <th v-else-if="item.channel === '0'">LEAD 1</th>
                <th v-else></th>
                <td v-if="item.evaluation">
                  {{ (item.x1 / 250).toFixed(2) }}s ~
                  {{ (item.x2 / 250).toFixed(2) }}s
                </td>
                <td v-else></td>
                <td>{{ item.evaluator }}</td>
                <td>
                  <span>{{ item.tagNote }}</span>
                </td>
                <!-- <td
                  v-if="
                    item.evaluation === '1' && item.model_name === 'model_b'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5">APC</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' && item.model_name === 'model_b1'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5"
                    >abnormal</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' &&
                      item.model_name === 'model_b1_009'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5"
                    >abnormal</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' && item.model_name === 'model_b2'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5"
                    >abnormal</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' && item.model_name === 'model_b2_05'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5"
                    >abnormal</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' &&
                      item.model_name === 'model_b_vpc_mi'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5">VPC</span>
                </td>

                <td
                  v-else-if="
                    item.evaluation === '2' &&
                      item.model_name === 'model_b_vpc_mi'
                  "
                >
                  <span class="badge bg_tagNote_2 text-dark fs-5"
                    >mi(std/ste)</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' && item.model_name === 'v1.0.1'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5">vpc</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '2' && item.model_name === 'v1.0.1'
                  "
                >
                  <span class="badge bg_tagNote_2 text-dark fs-5">mi</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '3' && item.model_name === 'v1.0.1'
                  "
                >
                  <span class="badge bg_tagNote_3 text-dark fs-5"
                    >arrhythmia</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '4' && item.model_name === 'v1.0.1'
                  "
                >
                  <span class="badge bg_tagNote_4 text-dark fs-5"
                    >vpc + arrhythmia</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '5' && item.model_name === 'v1.0.1'
                  "
                >
                  <span class="badge bg_tagNote_5 text-dark fs-5"
                    >mi + arrhythmia</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' && item.model_name === 'model_b3'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5"
                    >arrhythmia</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' && item.model_name === 'v1.0.2'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5">vpc</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '2' && item.model_name === 'v1.0.2'
                  "
                >
                  <span class="badge bg_tagNote_2 text-dark fs-5">mi</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '3' && item.model_name === 'v1.0.2'
                  "
                >
                  <span class="badge bg_tagNote_3 text-dark fs-5"
                    >arrhythmia</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '4' && item.model_name === 'v1.0.2'
                  "
                >
                  <span class="badge bg_tagNote_4 text-dark fs-5"
                    >vpc + arrhythmia</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '5' && item.model_name === 'v1.0.2'
                  "
                >
                  <span class="badge bg_tagNote_5 text-dark fs-5"
                    >mi + arrhythmia</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' && item.model_name === 'model_a1'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5">vpc/mi</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' && item.model_name === 'model_a1_v1'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5 text-dark"
                    >VPC,ST</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' && item.model_name === 'v1.0.3'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5">vpc</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '2' && item.model_name === 'v1.0.3'
                  "
                >
                  <span class="badge bg_tagNote_2 text-dark fs-5">ste</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '3' && item.model_name === 'v1.0.3'
                  "
                >
                  <span class="badge bg_tagNote_3 text-dark fs-5">std</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '4' && item.model_name === 'v1.0.3'
                  "
                >
                  <span class="badge bg_tagNote_4 text-dark fs-5"
                    >arrhythmia</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' &&
                      item.model_name === 'model_std_a1'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5">std</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' &&
                      item.model_name === 'model_ste_a1'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5">ste</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' &&
                      item.model_name === 'model_vpc_a1'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5">VPC</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' &&
                      item.model_name === 'model_ensemble_a1'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5">vpc</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '2' &&
                      item.model_name === 'model_ensemble_a1'
                  "
                >
                  <span class="badge bg_tagNote_2 text-dark fs-5">ste</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '3' &&
                      item.model_name === 'model_ensemble_a1'
                  "
                >
                  <span class="badge bg_tagNote_3 text-dark fs-5">std</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' && item.model_name === 'model_blw'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5">blw</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' && item.model_name === 'v1.0.4'
                  "
                >
                  <span class="badge bg_tagNote_1 text-dark fs-5">vpc</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '2' && item.model_name === 'v1.0.4'
                  "
                >
                  <span class="badge bg_tagNote_2 text-dark fs-5">ste</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '3' && item.model_name === 'v1.0.4'
                  "
                >
                  <span class="badge bg_tagNote_3 text-dark fs-5">std</span>
                </td>
                <td
                  v-else-if="
                    item.evaluation === '4' && item.model_name === 'v1.0.4'
                  "
                >
                  <span class="badge bg_tagNote_4 text-dark fs-5"
                    >arrhythmia</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' &&
                      item.model_name === 'model_unknown'
                  "
                >
                  <span class="badge bg_tagNote_other text-dark fs-5"
                    >Unknown</span
                  >
                </td>
                <td
                  v-else-if="
                    item.evaluation === '1' && item.model_name === 'model_b3_v2'
                  "
                >
                  <span class="badge bg_tagNote_4 text-dark fs-5"
                    >Arrhythmia</span
                  >
                </td>

                <td v-else-if="item.evaluation === '6'">
                  <span class="badge bg_tagNote_6 text-dark fs-5">PSVT</span>
                </td>
                <td v-else-if="item.evaluation === '7'">
                  <span class="badge bg_tagNote_other text-dark fs-5"
                    >ST-N</span
                  >
                </td>
                <td v-else-if="item.evaluation === '8'">
                  <span class="badge bg_tagNote_other text-dark fs-5"
                    >UnKnown</span
                  >
                </td>
                <td v-else-if="item.evaluation === '9'">
                  <span class="badge bg_tagNote_other text-dark fs-5">BBB</span>
                </td>
                <td v-else-if="item.evaluation === '10'">
                  <span class="badge bg_tagNote_other text-dark fs-5">OMI</span>
                </td>
                <td v-else>{{ item.evaluation }}</td> -->
                <td class="d-none d-xl-table-cell">
                  <a
                    href="#"
                    class="btn btn-primary me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#modifyEvaluationDataModal"
                    data-bs-whatever="id"
                    @click="modalData(i)"
                    >{{ t("diagnoses.evaluationinfo.table.editbtn") }}</a
                  >
                  <a
                    href="#"
                    class="btn btn-danger me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteEvaluationModal"
                    @click="modalData(i)"
                    >{{ t("diagnoses.evaluationinfo.table.delbtn") }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- <pre>{{ anomalyData.data[1] }}</pre> -->
  </div>
</template>

<script lang="ts">
import { onMounted, ref, computed } from "vue";
import {
  diagnoses,
  evaluationTagDataShow,
  selectedModelName,
  userphone,
  dbNum,
  modalData,
  modelName,
  dataSwitch,
  isEvaluated,
  role,
  anomalyData,
} from "@/composition/store";
import { useEvaluationMode } from "@/composition/index";
import { useI18n } from "vue-i18n";
import { useInitSciChart } from "@/composition/index";
export default {
  setup() {
    const { initSciChart } = useInitSciChart();
    const { t, locale } = useI18n();
    const {
      activeEvaluationMode,
      getAnomalyModels,
      updateReport,
    } = useEvaluationMode();
    onMounted(async () => {
      await getAnomalyModels();
    });
    const isShowData = (val) => {
      dataSwitch.value.anomaly = !val;
      initSciChart();
    };

    return {
      diagnoses,
      evaluationTagDataShow,
      selectedModelName,
      userphone,
      dbNum,
      modalData,
      modelName,
      activeEvaluationMode,
      updateReport,
      t,
      locale,
      dataSwitch,
      isShowData,
      isEvaluated,
      role,
      anomalyData,
    };
  },
};
</script>
<style scoped>
.bg_tagNote_1 {
  background-color: #ff333550;
}
.bg_tagNote_2 {
  background-color: #e6510050;
}

.bg_tagNote_3 {
  background-color: #ffee5850;
}

.bg_tagNote_4 {
  background-color: #1b5e2050;
}

.bg_tagNote_5 {
  background-color: #01579b50;
}

.bg_tagNote_other {
  background-color: #880e4f50;
}
</style>
