<template>
  <div
    class="modal fade"
    id="modifyEvaluationDataModal"
    tabindex="-1"
    aria-labelledby="modifyEvaluationDataModalLabel"
    aria-hidden="true"
    @keydown.enter="modifyEvaluationData(evaluationTagDataShow[tagIdx], noteMode)"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modifyEvaluationDataModalLabel">
            請確認此 Evaluation Tag 類型
          </h5>
          <button
            id="cancelSelectButton-3"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-4">
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked
                    value="ST-E"
                    v-model="noteMode"
                  />
                  <label class="form-check-label" for="flexRadioDefault2"> ST-E </label>
                </div>
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="ST-D"
                    v-model="noteMode"
                  />
                  <label class="form-check-label" for="flexRadioDefault2"> ST-D </label>
                </div>
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="OMI"
                    v-model="noteMode"
                  />
                  <label class="form-check-label" for="flexRadioDefault2"> OMI </label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="APC"
                    v-model="noteMode"
                  />
                  <label class="form-check-label" for="flexRadioDefault2"> APC </label>
                </div>
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="Atrial Fibrillation"
                    v-model="noteMode"
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    Atrial Fibrillation
                  </label>
                </div>
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="VPC"
                    v-model="noteMode"
                  />
                  <label class="form-check-label" for="flexRadioDefault2"> VPC </label>
                </div>
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="BBB"
                    v-model="noteMode"
                  />
                  <label class="form-check-label" for="flexRadioDefault2"> BBB </label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    value="Normal Sinus Rhythm"
                    v-model="noteMode"
                  />
                  <label class="form-check-label" for="flexRadioDefault1">
                    Normal Sinus Rhythm
                  </label>
                </div>
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="UnKnown"
                    v-model="noteMode"
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    Unknown
                  </label>
                </div>
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    value="Ignore"
                    v-model="noteMode"
                  />
                  <label class="form-check-label" for="flexRadioDefault2"> Ignore </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            id="cancelSelectButton-4"
          >
            Cancel
          </button>
          <button
            id="saveSelectButton"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="modifyEvaluationData(evaluationTagDataShow[tagIdx], noteMode)"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useModalFn } from "@/composition/index";
import { noteMode, evaluationTagDataShow, tagIdx } from "@/composition/store";
export default {
  setup() {
    const { modifyEvaluationData } = useModalFn();
    return { noteMode, modifyEvaluationData, evaluationTagDataShow, tagIdx };
  }
};
</script>
