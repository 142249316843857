
import { useEvaluationMode } from "@/composition/index";
import { diagnoses } from "@/composition/store";

export default {
  setup() {
    const { deleteReport } = useEvaluationMode();
    return { deleteReport, diagnoses };
  }
};
