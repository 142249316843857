<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="card w-100">
          <h5 class="card-header">
            <button
              class="btn btn-secondary"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#multi-collapse-chart"
              aria-expanded="true"
              aria-controls="collapseExample"
            >
              {{ t("diagnoses.chartinfo.title") }}
            </button>
          </h5>
          <div class="collapse multi-collapse show" id="multi-collapse-chart">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" class="d-none d-md-table-cell">
                        {{ t("diagnoses.chartinfo.id") }}
                      </th>
                      <th scope="col">{{ t("diagnoses.chartinfo.time") }}</th>
                      <th scope="col" class="text-nowrap">
                        {{ t("diagnoses.chartinfo.hr") }}
                      </th>
                      <th scope="col" class="text-nowrap">
                        {{ t("diagnoses.chartinfo.gain") }}
                      </th>
                      <th scope="col">{{ t("diagnoses.chartinfo.device") }}</th>
                      <th scope="col" v-if="role === 'admin'">synthetic</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in diagnoses.data" :key="item.diagnosis_id">
                      <th scope="row" class="d-none d-md-table-cell">
                        {{ item.diagnosis_id }}
                      </th>
                      <td v-if="dbNum === 'v2'">{{ item.create_date }}</td>
                      <td v-else-if="dbNum === 'v1'">{{ item.create_at }}</td>
                      <td>{{ item.measures[0].heart_rate[29] }}</td>
                      <td>{{ item.gain }}</td>
                      <td>{{ item.device_id }}</td>
                      <td v-if="role === 'admin'">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            :checked="item.synthetic"
                            @click="switchSynthetic(item.diagnosis_id, item.synthetic)"
                          />
                          <label class="form-check-label" for="flexSwitchCheckChecked">
                            {{ item.synthetic }}</label
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card w-100">
          <h5 class="card-header">
            <button
              class="btn btn-secondary"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#multi-collapse-report"
              aria-expanded="true"
              aria-controls="collapseExample"
            >
              {{ t("diagnoses.reportinfo.title") }}
            </button>
          </h5>
          <div class="collapse multi-collapse show" id="multi-collapse-report">
            <div class="d-flex justify-content-around mt-2">
              <button
                class="btn btn-success"
                @click="updateReport(diagnoses.data[0].diagnosis_id)"
                :disabled="btnDisabled"
              >
                {{ t("diagnoses.reportinfo.updatereport") }}
              </button>
              <button
                class="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#deleteReportModal"
                v-if="role === 'admin'"
              >
                {{ t("diagnoses.reportinfo.deletereport") }}
              </button>
            </div>
            <template v-if="reportData.data.length !== 0">
              <div class="card-body text-start" v-for="item in reportData.data">
                <p
                  class="text-center"
                  v-if="reportData.data[0].description[0].lang === 'zh_tw'"
                >
                  本次檢測不作為心臟疾病的專業臨床診斷依據
                </p>
                <p
                  class="text-center"
                  v-else-if="reportData.data[0].description[0].lang === 'zh_cn'"
                >
                  本次检测不作为心脏疾病的专业临床诊断依据
                </p>
                <p
                  class="text-center"
                  v-else-if="reportData.data[0].description[0].lang === 'en_us'"
                >
                  This test is not intended to be used as a basis for professional
                  clinical diagnosis of heart disease
                </p>
                <h5 class="card-title text-center">
                  {{ t("diagnoses.reportinfo.result") }}{{ item.type }}
                </h5>
                <ul
                  class="list-group"
                  v-if="reportData.data[0].description[0].lang === 'zh_tw'"
                >
                  <li class="list-group-item">
                    描述：{{ item.description[0].text_content }}
                  </li>
                  <li class="list-group-item">
                    建議：{{ item.suggestions[0].text_contents[0].text_content }}
                  </li>
                </ul>
                <ul
                  class="list-group"
                  v-else-if="reportData.data[0].description[0].lang === 'zh_cn'"
                >
                  <li class="list-group-item">
                    描述：{{ item.description[0].text_content }}
                  </li>
                  <li class="list-group-item">
                    建议：{{ item.suggestions[0].text_contents[0].text_content }}
                  </li>
                </ul>
                <ul
                  class="list-group"
                  v-else-if="reportData.data[0].description[0].lang === 'en_us'"
                >
                  <li class="list-group-item">
                    Description：{{ item.description[0].text_content }}
                  </li>
                  <li class="list-group-item">
                    Suggestion：{{ item.suggestions[0].text_contents[0].text_content }}
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DeleteReportModal></DeleteReportModal>
</template>

<script lang="ts">
import { computed } from "vue";
import DeleteReportModal from "@/components/diagnoses/modal/DeleteReportModal.vue";
import {
  diagnoses,
  reportData,
  userphone,
  btnDisabled,
  dbNum,
  role
} from "@/composition/store";
import {
  useEvaluationMode,
  useRequestDiagnoses,
  useShowECGChart
} from "@/composition/index";
import { useI18n } from "vue-i18n";
export default {
  components: {
    DeleteReportModal
  },
  setup() {
    const { t, locale } = useI18n();
    const { switchSynthetic } = useRequestDiagnoses();
    const { updateReport, deleteReport } = useEvaluationMode();
    computed(() => {
      console.log(reportData.value);
    });
    return {
      diagnoses,
      updateReport,
      reportData,
      deleteReport,
      userphone,
      btnDisabled,
      switchSynthetic,
      dbNum,
      t,
      locale,
      role
    };
  }
};
</script>
