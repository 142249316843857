<template>
  <div class="col-12 col-md-6" v-if="role === 'admin' || dbNum === 'v1'">
    <div class="card">
      <h5 class="card-header">
        <button
          class="btn btn-secondary"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#multi-collapse-tag"
          aria-expanded="true"
          aria-controls="collapseExample"
          @click="isShowData(dataSwitch.tag)"
        >
          {{ t("diagnoses.taginfo.title") }}
        </button>
      </h5>
      <div class="collapse multi-collapse" id="multi-collapse-tag">
        <div class="card-body">
          <div class="form-check form-switch d-none d-xl-inline-block mt-2">
            <input
              class="form-check-input"
              type="checkbox"
              id="tag-mode"
              v-model="isChecked"
            />
            <label class="form-check-label" for="tag-mode">{{
              t("diagnoses.taginfo.tagmode")
            }}</label>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ t("diagnoses.taginfo.table.channel") }}</th>
                <th scope="col">{{ t("diagnoses.taginfo.table.time") }}</th>
                <th scope="col">{{ t("diagnoses.taginfo.table.remark") }}</th>
                <th scope="col" class="d-none d-xl-table-cell">
                  {{ t("diagnoses.taginfo.table.operation") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in tagDataShow" :key="i">
                <th v-if="item.channel === '5'">avF</th>
                <th v-else-if="item.channel === '4'">aVL</th>
                <th v-else-if="item.channel === '3'">aVR</th>
                <th v-else-if="item.channel === '2'">LEAD 3</th>
                <th v-else-if="item.channel === '1'">LEAD 2</th>
                <th v-else-if="item.channel === '0'">LEAD 1</th>
                <th v-else></th>
                <td v-if="item.x1">
                  {{ (item.x1 / 250).toFixed(2) }}s ~ {{ (item.x2 / 250).toFixed(2) }}s
                </td>
                <td v-else></td>
                <td>{{ item.note }}</td>
                <td class="d-none d-xl-table-cell">
                  <a
                    href="#"
                    class="btn btn-primary me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#modifyModal"
                    @click="modalData(i)"
                    >{{ t("diagnoses.taginfo.table.editbtn") }}</a
                  >
                  <a
                    href="#"
                    class="btn btn-danger me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    @click="modalData(i)"
                    >{{ t("diagnoses.taginfo.table.delbtn") }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, ref } from "vue";
import {
  tagDataShow,
  isChecked,
  isActive,
  userphone,
  role,
  dbNum,
  modalData,
  dataSwitch,
  diagnosesid
} from "@/composition/store";
import { useI18n } from "vue-i18n";
import { useInitSciChart } from "@/composition/index";
export default {
  setup() {
    const { initSciChart } = useInitSciChart();
    const { t, locale } = useI18n();
    role.value = JSON.parse(localStorage.getItem("userinfo")).role;
    const isShowData = (val) => {
      dataSwitch.value.tag = !val;
      initSciChart();
      // console.log(dataSwitch.value.tag);
    };
    //   return { display: username.value === "77311699" ? "none" : "block" };
    // });
    // const isChecked = ref(false);
    // const focus = ref(null);
    // const switchToggle = (e) => {
    //   // e.preventDefault();
    //   focus.value.focus();
    //   isChecked.value = !isChecked.value;
    //   console.log(e.keyCode);
    //   console.log(isChecked.value);
    //   return false;
    // };
    // const tagModeEnable: HTMLInputElement = <HTMLInputElement>(
    //   document.getElementById("tag-mode")
    // );
    // document.body.addEventListener("keydown", (e) => {
    //   console.log(e.keyCode);
    //   e.preventDefault();
    //   switch (e.keyCode) {
    //     case 32:
    //       tagModeEnable.focus();
    //       tagModeEnable.checked = !tagModeEnable.checked;
    //       console.log(tagModeEnable.checked);
    //   }
    // if (tagModeEnable.checked === true) {
    //   simpleDataPointSelectionModifier.isEnabled = true;
    //   zoomPanModifier.isEnabled = false;
    //   xAxisDragModifier.isEnabled = true;
    //   scichartRoot.setAttribute("data-bs-toggle", "modal");
    //   scichartRoot.setAttribute("data-bs-target", "#exampleModal");
    // } else {
    //   simpleDataPointSelectionModifier.isEnabled = false;
    //   zoomPanModifier.isEnabled = true;
    //   xAxisDragModifier.isEnabled = false;
    //   scichartRoot.removeAttribute("data-bs-toggle");
    //   scichartRoot.removeAttribute("data-bs-target");
    // }
    // });
    return {
      tagDataShow,
      isChecked,
      isActive,
      userphone,
      dbNum,
      modalData,
      isShowData,
      t,
      locale,
      dataSwitch,
      role
      // isShow
      // switchToggle,
      // focus
    };
  }
};
</script>

<style></style>
